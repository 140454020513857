import React from "react"
import { FaPhoneAlt, FaRegEnvelope, FaAt, FaLinkedinIn } from 'react-icons/fa';

import Layout from "../components/layout"
import SEO from "../components/seo"
import GoogleMap from '../components/GoogleMap'
import envelope from "../images/svg/undraw_envelope.svg"
import ContactForm from "../components/ContactForm";

const clickMailto = () => {
    const n = "jan.johansson";
    const d = "softtile.fi";
    const mailto_link = 'mailto:' + n + "@" + d;
    window.location = mailto_link;
}

const ContactPage = () => (
    <Layout>
        <SEO title="Yhteystiedot" description={'Ota yhteyttä ja keskustellaan lisää! Katso osoitetiedot ja kartalta missä toimisto sijaitsee.'}/>
        <div className="contact-page">
            <div className={'columns'}>
                <div className={'column'}>
                    <h1>Ota yhteyttä</h1>
                    <br/>
                    <a href={'https://linkedin.com/in/softtilejohansson'} target={'_blank'} rel={'noreferrer'}>Jan Johansson <FaLinkedinIn style={{marginBottom: '-2px'}}/></a>
                    <div>
                        <a className={'phone'} href={'tel:+358505410701'}><FaPhoneAlt style={{marginBottom: '-2px'}}/> +358 50 5410 701</a>
                    </div>
                    <div>
                        <button className={'mail'} onClick={() => {clickMailto()}}><FaRegEnvelope style={{marginBottom: '-3px'}} /> jan.johansson<FaAt style={{fontSize: '13px'}}/>softtile.fi</button>
                    </div>
                    <br/>
                    <h2 style={{fontWeight: 600}}>Osoite</h2>
                    <p>Tervajoentie 6</p>
                    <p>66440 Tervajoki</p>
                    <p>Finland</p>
                    <img className="envelope"
                         src={envelope}
                         alt="Envelope"
                    />
                </div>
                <div className={'column is-three-fifths'}>
                    <GoogleMap />
                </div>
            </div>
            <ContactForm/>
        </div>
    </Layout>
)

export default ContactPage
