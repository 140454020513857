import React from 'react'
import GoogleMapReact from 'google-map-react'
import Marker from "./Marker";

const defaultProps = {
    center: {
        lat: 63.006234,
        lng: 22.148337,
    },
    zoom: 17,
}

const AnyReactComponent = ({ text }) => <div>{text}</div>

const GoogleMap = () => (
    <div style={{ height: '40vh', width: '100%' }}>
        <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyDDBa6mQ7FpQI5yQDANyfhf9mfDtfp8xkE' }}
            defaultCenter={defaultProps.center}
            defaultZoom={defaultProps.zoom}
        >
            <AnyReactComponent
                lat={63.006234}
                lng={22.148467}
                text={'SoftTile Oy'}
            />
            <Marker
                lat={63.006230}
                lng={22.148337}
            />
        </GoogleMapReact>
    </div>
)

export default GoogleMap