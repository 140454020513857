import React from 'react';
import markerImage from '../images/marker.png'

const Marker = () => {
    return (
        <div className="marker">
            <img src={markerImage} alt={'Marker'}/>
        </div>
    );
};

export default Marker;